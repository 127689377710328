<template>
  <div
    style="height: 100%"
    class="body1"
    v-loading.fullscreen.lock="fullscreenLoading"
  >
    <div class="ss_div" :style="{ width: isMobel ? '340px' : '380px' }">
      <el-input
        v-model="input"
        clearable
        placeholder="请输入内容"
        class="input-with-select"
      >
        <el-select
          style="width: 150px; font-size: 12px"
          v-model="select"
          slot="prepend"
        >
          <el-option label="展位号(Booth No.)" value="1"></el-option>
          <el-option label="其他(Others)" value="2"></el-option>
        </el-select>
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="sousuo"
        ></el-button>
      </el-input>
      <div
        class="ssjg_div"
        v-show="ssjg"
        v-loading="sslistLoading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div
          v-for="(item, p) in ssjgarr"
          :key="p"
          class="cxlist"
          @click="czxz(item)"
          :style="{ height: select == 1 ? '70px' : '70px' }"
        >
          <div v-if="select == 1" class="llspan">
            <div
              class="lldiv"
              style="
                display: inline-block;
                width: 210px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
              :title="item.CompanyZHName + '\n' + item.CompanyENName"
            >
              {{ item.CompanyZHName }}
              <br />
              {{ item.CompanyENName }}
            </div>

            <div style="margin-left: 15px">{{ item.BoothName }}</div>
            <div style="margin-left: 15px">F{{ item.szlc }}</div>
          </div>
          <div class="llspan" v-else>
            {{ item.CompanyZHName }}<br />{{ item.CompanyENName }}
          </div>
        </div>
      </div>
    </div>
    <div
      :style="{ height: item == nowlc ? '100%' : 0 }"
      v-for="(item, i) in lcs"
      :key="i"
    >
      <div
        class="container"
        :id="'container' + item"
        v-if="item == nowlc"
      ></div>
    </div>
    <div class="lc_div" :style="{ bottom: xinxi ? '300px' : '150px' }">
      <div
        :class="nowlc == item ? 'cs_div checked' : 'cs_div'"
        v-for="(item, i) in lcs"
        :key="i"
        @click="xzlc(item)"
      >
        {{ item }}
      </div>
    </div>
    <div class="right_div" v-if="daohang">
      <div class="top_lidiv" v-show="topshow">
        <img
          class="wz_img"
          src="https://indoor-imapdata-1255979306.cos.ap-beijing.myqcloud.com/webmap/common/marker_red.png"
        />
        <span>{{ fromname }}</span>
      </div>
      <div class="name_div">
        <div class="name_div_li">
          <img
            class="wz_img"
            src="https://indoor-imapdata-1255979306.cos.ap-beijing.myqcloud.com/webmap/common/marker_red.png"
          />
          <span class="name_span">
            {{ toname }}
          </span>
          <span
            class="ckxx"
            @click="
              () => {
                xinxi = !xinxi;
              }
            "
            >{{ xinxi ? "收起(Fold)" : "详情（Details）" }}</span
          >
          <img
            @click="close"
            class="gb_img"
            src="https://indoor-imapdata-1255979306.cos.ap-beijing.myqcloud.com/webmap/common/close.png"
          />
        </div>
      </div>
      <div v-if="xinxi && typeof zwxq.name_zh == 'string'" class="zhanwei_div">
        <div class="lsbj">展位编号</div>
        {{ zwxq.BoothName }}
        <div class="zwxxli">
          <p class="zwxxp">
            <span class="lsbj">公司名称</span>{{ zwxq.name_zh }};
          </p>
          <p class="zwxxp">
            <span class="lsbj">Exhibitor</span>{{ zwxq.name_en }};
          </p>
          <p class="zwxxp" v-if="zwxq.logo">
            <span class="lsbj">LOGO</span
            ><img style="height: 40px" :src="zwxq.logo" alt="暂无信息" />
          </p>
          <p class="zwxxp">
            <span class="lsbj">产品信息</span>{{ zwxq.product_zh }};
          </p>
          <p class="zwxxp">
            <span class="lsbj">Products</span>{{ zwxq.product_en }};
          </p>
        </div>
      </div>
      <div
        v-if="xinxi && zwxq.name_zh.constructor == Array"
        class="zhanwei_div"
        id="zhanweixinxi_div"
      >
        <!-- <div class="lsbj">展位编号</div>
        {{ zwxq.BoothName }}
        <hr> -->
        <div
          class="zwxxli"
          v-show="!zzjtgs"
          v-for="(item, i) in zwxq.name_zh"
          :key="i"
        >
          <p class="zwxxp" @click="nbxzgs(i)">
            <span class="lsbj">公司名称</span
            ><span class="zwxxpspan">{{ item }};</span>
            <br />
            <span class="lsbj">Exhibitor</span
            ><span class="zwxxpspan">{{ zwxq.name_en[i] }};</span>
          </p>
          <!-- <p class="zwxxp">
            <span class="lsbj">Exhibitor</span>{{ zwxq.name_en }};
          </p>
          <p class="zwxxp" v-if="zwxq.logo">
            <span class="lsbj">LOGO</span
            ><img style="height: 40px" :src="zwxq.logo" alt="暂无信息" />
          </p>
          <p class="zwxxp">
            <span class="lsbj">产品信息</span>{{ zwxq.product_zh }};
          </p>
          <p class="zwxxp">
            <span class="lsbj">Products</span>{{ zwxq.product_en }};
          </p> -->
        </div>
        <div class="zwxxli" v-if="zzjtgs">
          <p class="zwxxp">
            <span class="lsbj">公司名称</span>{{ zwxq.name_zh[gsxz] }};
          </p>
          <p class="zwxxp">
            <span class="lsbj">Exhibitor</span>{{ zwxq.name_en[gsxz] }};
          </p>
          <p class="zwxxp" v-if="zwxq.logo[gsxz]">
            <span class="lsbj">LOGO</span
            ><img style="height: 40px" :src="zwxq.logo[gsxz]" alt="暂无信息" />
          </p>
          <p class="zwxxp">
            <span class="lsbj">产品信息</span>{{ zwxq.product_zh[gsxz] }};
          </p>
          <p class="zwxxp">
            <span class="lsbj">Products</span>{{ zwxq.product_en[gsxz] }};
          </p>
        </div>
      </div>
      <div
        class="button_div"
        @click="lujing"
        :style="{ width: isMobel ? '280px' : '460px' }"
      >
        {{ buttntxt }}
      </div>
    </div>
  </div>
</template>
<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { CSS3DRenderer } from "three/examples/jsm/renderers/CSS3DRenderer.js";
import { Grid, Astar } from "fast-astar";
// import Stats from "./Stats";

export default {
  data() {
    return {
      key_string_base64: null,

      gsxz: null,
      zzjtgs: false,

      select: "1",
      orderlist: [],

      xinxi: false,
      fromname: "请在地图中选择起点",
      buttntxt: "到这去（Go Here）",
      toname: "",
      topshow: false,
      daohang: false,
      testlist: [],
      zwxq: {},
      step: 50, // 步长
      Hwidth: 560, //表格 展位宽度
      Hheight: 490, //表格 展位高度
      isMobel: null,
      zwg: 2,
      lcs: [1],
      nowlc: 1,
      allpoint: [],
      fullscreenLoading: false,
      sslistLoading: false,
      ltarr: [],
      klcdh: false,
      renderEnabled: true,
      progress: 0,
      input: "",
      ssjg: false,
      allzw: [],
      ssjgarr: [],
      intx: null,
      inty: null,
      forintent: 0,
      code_value: null,

      indexx: 0,
    };
  },
  methods: {
    nbxzgs(i) {
      this.gsxz = i;
      this.zzjtgs = true;
      // document.getElementById('zhanweixinxi_div').scrollIntoView()
      document.getElementById("zhanweixinxi_div").scrollTop = 0;
    },
    async sousuo() {
      this.ssjg = true;
      this.sslistLoading = true;
      //orderlist
      if (this.ssjgarr) {
        this.ssjgarr = [];
      }
      var ssjgarr = [];
      this.orderlist.forEach((e) => {
        if (e.BoothName && e.BoothName.includes(this.input)) {
          // console.log(e)
          // if (!ssjgarr.includes(e.BoothName)) {
          ssjgarr.push(e);
          // }
          // ssjgarr.push(e.BoothName);
        } else if (e.CompanyZHName && e.CompanyZHName.includes(this.input)) {
          ssjgarr.push(e);
        } else if (e.CompanyENName && e.CompanyENName.includes(this.input)) {
          ssjgarr.push(e);
        } else if (e.product_en && e.product_en.includes(this.input)) {
          ssjgarr.push(e);
        } else if (e.product_zh && e.product_zh.includes(this.input)) {
          ssjgarr.push(e);
        }
      });
      // console.log(ssjgarr);
      ssjgarr.forEach((item) => {
        for (let i in this.allzw) {
          let e = this.allzw[i];
          for (let j in e.hall) {
            let a = e.hall[j];
            for (let m in a.booth_info) {
              let q = a.booth_info[m];
              if (q.BoothName == item.BoothName) {
                // q.szlc = e.hall_floor;
                // console.log(item)
                // q.name_zh = item.CompayZHName
                // q.name_en = item.CompayENName
                // this.ssjgarr.push(q);

                item.szlc = e.hall_floor;
                // item.name_zh = item.CompayZHName
                // item.name_en = item.CompayENName

                item.CoordinateX = q.CoordinateX;
                item.CoordinateY = q.CoordinateY;

                // console.log(item,q)
                this.ssjgarr.push(item);
                break;
              }
            }
          }
        }
      });
      // console.log(this.ssjgarr);
      this.sslistLoading = false;
    },
    czxz(item) {
      console.log(item);
      let isMobel = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (item.szlc == this.nowlc) {
        this.input = item.BoothName;
        this.select = "1";
        var SELECTED;
        let that = this;
        this.intx = item.CoordinateX;
        this.inty = item.CoordinateY;
        this.camera.position.set(item.CoordinateX, item.CoordinateY, 40);

        this.controls.target = new THREE.Vector3(
          item.CoordinateX,
          item.CoordinateY,
          0
        );
        // return
        this.controls = null;
        this.controls = new OrbitControls(
          this.camera,
          this.labelRenderer.domElement,
          new THREE.Vector3(item.CoordinateX, item.CoordinateY, 0)
        );

        //缩放
        this.controls.enableZoom = true;
        //限制缩放
        this.controls.minDistance = 30;
        if (isMobel) {
          this.controls.maxDistance = 450;
        } else {
          this.controls.maxDistance = 350;
        }

        //旋转
        this.controls.enableRotate = true;
        //上下翻转的最大角度
        this.controls.maxPolarAngle = 1.9;
        //上下翻转的最小角度
        this.controls.minPolarAngle = 1.5;
        //左右翻转
        this.controls.maxAzimuthAngle = 0.2;
        this.controls.minAzimuthAngle = -0.2;

        //修改鼠标按键
        this.controls.mouseButtons = {
          LEFT: THREE.MOUSE.PAN,
          MIDDLE: THREE.MOUSE.DOLLY,
          RIGHT: THREE.MOUSE.ROTATE,
        };
        //修改移动端触摸事件 单指移动
        this.controls.touches = {
          ONE: THREE.TOUCH.PAN,
          TWO: THREE.TOUCH.DOLLY_ROTATE,
        };
        //监听change事件
        this.controls.addEventListener("change", function () {
          that.timeRender();
        });

        let container = document.getElementById("container" + this.nowlc);
        // 渲染器添加到页面容器
        container.appendChild(this.renderer.domElement);

        this.mouse.x =
          ((container.clientWidth + 1) /
            2 /
            this.renderer.domElement.clientWidth) *
            2 -
          1;
        this.mouse.y =
          -(
            (container.clientHeight + 1) /
            2 /
            this.renderer.domElement.clientHeight
          ) *
            2 +
          1;

        this.raycaster.setFromCamera(this.mouse, this.camera);
        this.scene.children.forEach((child) => {
          if (child instanceof THREE.Mesh) {
            if (child.name == item.BoothName) {
              this.objects3.push(child);
              // console.log(child,this.objects3);
            }
          }
        });
        var intersects = this.raycaster.intersectObjects(this.objects3);
        console.log(intersects);
        // return
        if (this.indexx == 0) {
          this.indexx++;
          this.czxz(item);
          return;
        }
        if (intersects.length > 0) {
          if (SELECTED != intersects[0].object) {
            if (this.SELECTED1 && this.SELECTED1.xxxx) {
              //选择其他展位时还原之前展位颜色
              let map = new THREE.CanvasTexture(
                that.getTextCanvas(
                  this.SELECTED1.name,
                  this.SELECTED1.width,
                  this.SELECTED1.height,
                  "#36855f"
                )
              );
              this.SELECTED1.material.forEach((e) => {
                if (e.map) {
                  e.map = map;
                } else {
                  e.color.set(0x36855f);
                }
              });
              this.SELECTED = null;
            }
            SELECTED = intersects[0].object;
            this.SELECTED1 = intersects[0].object;
            //点击背景等时不弹出导航框
            //判断是否点击为展位
            if (SELECTED.xxxx) {
              //改变颜色
              let map = new THREE.CanvasTexture(
                that.getTextCanvas(
                  SELECTED.name,
                  SELECTED.width,
                  SELECTED.height,
                  "#fd8a45"
                )
              );
              SELECTED.material.forEach((e) => {
                if (e.map) {
                  e.map = map;
                } else {
                  e.color.set(0xfd8a45);
                }
              });

              SELECTED.xxxx.name_zh = item.CompanyZHName;
              SELECTED.xxxx.name_en = item.CompanyENName;
              SELECTED.xxxx.product_zh = item.product_zh;
              SELECTED.xxxx.product_en = item.product_en;
            }
            if (!SELECTED.name) {
              if (this.forintent > 2) {
                return;
              }
              // this.czxz(item);
              this.forintent++;
              return;
            }
          }
          console.log(SELECTED);
          this.forintent = 0;
          if (this.topshow) {
            //起点
            this.fromname = SELECTED.name;
            this.start = SELECTED;
            this.start.lc = this.nowlc;
          } else {
            //终点
            this.daohang = true;
            this.toname = SELECTED.name;
            this.end = SELECTED;
            this.zwxq = SELECTED.xxxx;
            this.end.lc = this.nowlc;
          }
          // console.log(this.start, this.end);
        }
      } else {
        alert("请前往该展位所在楼层查询");
      }
      this.ssjg = false;
    },
    xzlc(e) {
      if (e != this.nowlc) {
        this.fullscreenLoading = true;
        //删除画出的模型
        this.deletemesh();
        this.ewarr = null;
        this.objects = [];
        this.testlist = [];
        this.nowlc = e;
        setTimeout(() => {
          cancelAnimationFrame(this.myReq);
          this.camera = null;
          this.scene = null;
          //   this.scene.dispose();
          this.renderer = null;
          this.labelRenderer = null;
          this.raycaster = new THREE.Raycaster();
          this.mouse = new THREE.Vector2();
          //画出新的模型
          this.init();
          this.background();
          this.animate();
        }, 10);
      }
    },
    deletemesh() {
      //递归调用删除画出的模型
      var allChildren = this.scene.children;
      for (const i in allChildren) {
        let element = allChildren[i];
        this.scene.remove(element);
      }
      if (this.scene.children.length > 0) {
        this.deletemesh();
        return;
      }
      // console.log(this.scene);
    },
    getTextCanvas(text, w, h, color) {
      //文字贴图
      var width = w * 14,
        height = h * 14;
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, width, height);
      ctx.fillStyle = "#ffffff";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      if (w < h) {
        //如果高大于宽，旋转90°
        ctx.translate(width / 2, height / 2); //设置旋转点
        if (text.includes("A")) {
          ctx.rotate(-Math.PI / 2);
        } else {
          ctx.rotate(Math.PI / 2); //旋转
        }
        ctx.fillText(text, 0, 0);
      } else {
        ctx.fillText(text, width / 2, height / 2);
      }
      return canvas;
    },
    init() {
      //搜索接口
      this.axios
        .get(
          `/emanual/interface_new.php?key_string_base64=${this.key_string_base64}&interface_index=map_sreach_interface`
        )
        .then((res) => {
          // console.log(res.data);
          this.orderlist = res.data;
        });
      this.axios
        .get(
          `/emanual/interface_new.php?key_string_base64=${this.key_string_base64}&interface_index=booth_naviman_speed`
        )
        .then(({ data }) => {
          // console.log(data)
          this.code_value = data[0].code_value;
        });
      //初始化A*表格
      let grid = new Grid({
        col: this.Hwidth, // col
        row: this.Hheight, // row
        render: function () {},
      });
      this.ewarr = grid;
      // 获取3D容器元素
      let container = document.getElementById("container" + this.nowlc);
      // 构建场景
      let scene = new THREE.Scene();
      this.scene = scene;
      // 构建相机
      // 参数说明 视野：90度，长宽比，近平面裁剪：0.1，远平面裁剪：1000
      let camera = new THREE.PerspectiveCamera(
        90,
        container.clientWidth / container.clientHeight,
        1,
        700
      );
      // //光源设置
      // //点光源
      // var point = new THREE.PointLight(0xffffff);
      // point.position.set(400, 200, 300); //点光源位置
      // this.scene.add(point); //点光源添加到场景中
      // //环境光
      // var ambient = new THREE.AmbientLight(0x888888);
      // this.scene.add(ambient);

      // 构建渲染器
      let renderer = new THREE.WebGLRenderer({ antialias: true });
      // 设置宽高
      renderer.setSize(container.clientWidth, container.clientHeight);
      // 构建多维数据集 展馆展位数据
      var times = new Date().getTime();
      // this.axios.get(`/data/booth.json?${times}`).then((res) => {
      this.axios
        .get("/emanual/interface_booth.php?agree=wf_mobile_booth")
        .then((res) => {
          this.allzw = res.data;
          let data = res.data;
          //楼层数组
          this.lcs = data.map((e) => {
            return e.hall_floor;
          });

          //当前选中层数  nowlc
          let now_floor;
          for (let i = 0; i < res.data.length; i++) {
            let element = res.data[i];
            if (element.hall_floor == this.nowlc) {
              now_floor = element.hall;
            }
          }

          //所有展馆墙位置数组
          let hall = now_floor.map((e) => {
            if (e.hall_array) {
              let nstr = e.hall_array.replace(/\r\n/g, "");
              let arr1 = nstr.split(":");
              var a = [];
              for (let i in arr1) {
                var str;
                if (arr1[i].includes("墙")) {
                  str = arr1[i].slice(0, arr1[i].indexOf("墙"));
                } else {
                  str = arr1[i];
                }
                if (str) {
                  if (str[str.length - 1] == ";") {
                    str = str.slice(0, str.length - 1);
                  }
                  a.push(str.split(";"));
                }
              }
              let obj = {};
              obj.hall_rotation_angle = e.hall_rotation_angle;
              obj.arr = a;
              return obj;
            } else {
              return false;
            }
          });

          //数据标准化
          for (let i in hall) {
            if (hall[i]) {
              let q = hall[i].arr;
              for (let j in q) {
                let arr2 = q[j];
                for (let index = 1; index < arr2.length; index++) {
                  let q1 = arr2[index].split(",");
                  let q2 = arr2[index - 1].split(",");

                  //Y颠倒
                  q1[1] = this.Hheight * this.step - q1[1];
                  q2[1] = this.Hheight * this.step - q2[1];

                  this.hallinit(q1, q2);
                }
              }
            }
          }
          //展位数据标准化
          for (let i = 0; i < now_floor.length; i++) {
            let item = now_floor[i];
            if (item) {
              let basx = item.hall_global_offset_x;
              let basy = item.hall_global_offset_y;
              item.booth_info.forEach((e) => {
                // if(e.BoothName.includes("0108")){
                //   console.log(e)
                // }
                //调整展厅位置
                if (item.hall_rotation_angle == 90) {
                  let q = e.CoordinateX;
                  e.CoordinateX = item.MapHeight - e.CoordinateY;
                  e.CoordinateY = q;
                  let p = e.BoothWidht;
                  e.BoothWidht = e.BoothHeight;
                  e.BoothHeight = p;

                  if (e.guide_x && e.guide_y) {
                    //含有出入口信息的进行位置转换
                    let p = e.guide_x;
                    e.guide_x = item.MapHeight - e.guide_y - e.BoothWidht;
                    e.guide_y = p;
                  }
                } else if (item.hall_rotation_angle == 270) {
                  let o = e.CoordinateX;
                  e.CoordinateX = e.CoordinateY;
                  e.CoordinateY = o;
                  let p = e.BoothWidht;
                  e.BoothWidht = e.BoothHeight;
                  e.BoothHeight = p;

                  if (e.guide_x && e.guide_y) {
                    //含有出入口信息的进行位置转换
                    let p = e.guide_x;
                    e.guide_x = e.guide_y;
                    e.guide_y = p;
                  }
                } else if (item.hall_rotation_angle == 0) {
                  e.CoordinateY = item.MapHeight - e.CoordinateY;

                  if (e.guide_x && e.guide_y) {
                    //含有出入口信息的进行位置转换
                    e.guide_y = item.MapHeight - e.guide_y;
                  }
                }

                //展馆四周展位方向
                if (e.BoothAngle == 90 || e.BoothAngle == 270) {
                  let p = e.BoothWidht;
                  e.BoothWidht = e.BoothHeight;
                  e.BoothHeight = p;
                }

                if (e.guide_x && e.guide_y) {
                  //含有出入口信息的进行位置转换
                  e.guide_x = Number(basx) + Number(e.guide_x);
                  e.guide_y =
                    this.Hheight * this.step -
                    (Number(basy) + Number(e.guide_y));
                }

                e.CoordinateX = Number(basx) + Number(e.CoordinateX);
                //左上角为坐标原点
                e.CoordinateY =
                  this.Hheight * this.step -
                  (Number(basy) + Number(e.CoordinateY));

                e.BoothWidht = e.BoothWidht / this.step;
                e.BoothHeight = e.BoothHeight / this.step;

                let obj = e;
                obj.hall_rotation_angle = item.hall_rotation_angle;

                //展位位置
                if (obj.hall_rotation_angle == 90) {
                  obj.CoordinateX =
                    obj.CoordinateX / this.step - obj.BoothWidht / 2;
                  obj.CoordinateY =
                    obj.CoordinateY / this.step - obj.BoothHeight / 2;
                } else if (obj.hall_rotation_angle == 270) {
                  obj.CoordinateX =
                    obj.CoordinateX / this.step + obj.BoothWidht / 2;
                  obj.CoordinateY =
                    obj.CoordinateY / this.step - obj.BoothHeight / 2;
                } else if (obj.hall_rotation_angle == 0) {
                  obj.CoordinateX =
                    obj.CoordinateX / this.step + obj.BoothWidht / 2;
                  obj.CoordinateY =
                    obj.CoordinateY / this.step - obj.BoothHeight / 2;
                } else {
                  obj.CoordinateX =
                    obj.CoordinateX / this.step + obj.BoothWidht / 2;
                  obj.CoordinateY =
                    obj.CoordinateY / this.step + obj.BoothHeight / 2;
                }

                //展馆四周展位位置
                if (obj.BoothAngle == 90) {
                  obj.CoordinateX = obj.CoordinateX + obj.BoothWidht / 2;
                  obj.CoordinateY = obj.CoordinateY - obj.BoothHeight;
                } else if (obj.BoothAngle == 270) {
                  obj.CoordinateX = obj.CoordinateX - obj.BoothWidht / 2;
                  obj.CoordinateY = obj.CoordinateY - obj.BoothHeight;
                }

                this.testlist.push(obj);
              });
            }
          }
          // console.log(this.testlist);
          //画出展位
          this.threeinit();
        });

      //楼梯位置
      this.axios
        .get(
          `/emanual/interface_new.php?key_string_base64=${this.key_string_base64}&interface_index=cross_floor_point`
        )
        // .get("/data/stairs.json")
        .then(({ data }) => {
          // console.log(data);
          this.ltarr = data;
          let cubeMaterial1 = new THREE.MeshBasicMaterial({
            color: 0x9932cc,
          });
          for (const key in this.ltarr) {
            let e = this.ltarr[key];
            e.cfp_y = this.Hheight * this.step - e.cfp_y;
            if (e.hall_floor == this.nowlc) {
              var cylinderGeometry = new THREE.BoxGeometry(3, 3, this.zwg);
              var texture = new THREE.CanvasTexture(
                this.getTextCanvas(e.cross_floor_point_name, 5, 5, "#9932cc")
              );
              var materials = [
                cubeMaterial1,
                cubeMaterial1,
                cubeMaterial1,
                cubeMaterial1,
                new THREE.MeshBasicMaterial({
                  map: texture,
                }), // top
                cubeMaterial1,
              ];
              var cylinder = new THREE.Mesh(cylinderGeometry, materials);

              cylinder.position.z = 0;
              cylinder.position.x = e.cfp_x / this.step;
              cylinder.position.y = e.cfp_y / this.step;

              cylinder.name = e.cross_floor_point_name;
              cylinder.lt = e;

              this.scene.add(cylinder);
            }
          }
        });

      //判断是否为移动端
      let isMobel = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      let xjx, xjy, xjz, zz;
      if (isMobel) {
        xjz = 450;
        xjx = this.Hwidth / 2;
        xjy = this.Hheight / 2;
        zz = 100;
      } else {
        xjx = this.Hwidth / 2;
        xjz = 150;
        xjy = this.Hheight / 2;
        zz = 70;
      }

      // 相机位置
      camera.position.set(xjx, xjy - zz, xjz);

      // 赋值属性
      // this.scene = scene;
      this.camera = camera;
      this.renderer = renderer;

      this.labelRenderer = new CSS3DRenderer();
      this.labelRenderer.setSize(container.clientWidth, container.clientHeight);
      this.labelRenderer.domElement.style.position = "absolute";
      container.appendChild(this.labelRenderer.domElement);

      //创建控件对象   第三个参数为相机看向的点 修改OrbitControls源文件新增参数
      this.controls = new OrbitControls(
        this.camera,
        this.labelRenderer.domElement,
        new THREE.Vector3(xjx, xjy, 0)
      );

      //背景色
      this.renderer.setClearColor(0xbfd1e5);

      //缩放
      this.controls.enableZoom = true;
      //限制缩放
      this.controls.minDistance = 30;
      if (isMobel) {
        this.controls.maxDistance = 450;
      } else {
        this.controls.maxDistance = 350;
      }

      //旋转
      this.controls.enableRotate = true;
      //上下翻转的最大角度
      this.controls.maxPolarAngle = 1.9;
      //上下翻转的最小角度
      this.controls.minPolarAngle = 1.5;
      //左右翻转
      this.controls.maxAzimuthAngle = 0.2;
      this.controls.minAzimuthAngle = -0.2;

      //修改鼠标按键
      this.controls.mouseButtons = {
        LEFT: THREE.MOUSE.PAN,
        MIDDLE: THREE.MOUSE.DOLLY,
        RIGHT: THREE.MOUSE.ROTATE,
      };
      //修改移动端触摸事件 单指移动
      this.controls.touches = {
        ONE: THREE.TOUCH.PAN,
        TWO: THREE.TOUCH.DOLLY_ROTATE,
      };
      //监听change事件
      let that = this;
      this.controls.addEventListener("change", function () {
        that.timeRender();
      });

      //   //创建三维坐标系
      //   this.scene.add(new THREE.AxesHelper(300))

      // 渲染器添加到页面容器
      container.appendChild(renderer.domElement);
    },
    hallinit(w, e) {
      var a = 0;
      if (this.$route.query.wall) {
        a = this.$route.query.wall;
      }
      // console.log(this.$route)
      if (a == 1) {
        var points1 = [];
        var material1 = new THREE.LineBasicMaterial({
          color: 0xff0000,
        });
        points1.push(new THREE.Vector3(w[0] / this.step, w[1] / this.step, 0));
        points1.push(new THREE.Vector3(e[0] / this.step, e[1] / this.step, 0));
        var geometry1 = new THREE.BufferGeometry().setFromPoints(points1);
        var line1 = new THREE.Line(geometry1, material1);
        this.scene.add(line1);
        this.timeRender();
      }

      //A*表格定义为墙
      if (w[0] == e[0]) {
        let min = Math.min(
          Math.floor(w[1] / this.step),
          Math.floor(e[1] / this.step)
        );
        let max = Math.max(
          Math.floor(w[1] / this.step),
          Math.floor(e[1] / this.step)
        );
        for (let k = min; k <= max; k++) {
          this.ewarr.set([Math.floor(w[0] / this.step), k], "value", 1);
        }
      } else if (w[1] == e[1]) {
        let min = Math.min(
          Math.floor(w[0] / this.step),
          Math.floor(e[0] / this.step)
        );
        let max = Math.max(
          Math.floor(w[0] / this.step),
          Math.floor(e[0] / this.step)
        );
        for (let k = min; k <= max; k++) {
          this.ewarr.set([k, Math.floor(w[1] / this.step)], "value", 1);
        }
      }
    },
    threeinit() {
      //画出展位
      var zaarr = [];

      var edgesMtl = new THREE.LineBasicMaterial({ color: 0x61c294 });

      for (let k in this.testlist) {
        let a = this.testlist[k];

        //threejs图内长宽
        let width = a.BoothWidht;
        let height = a.BoothHeight;
        
        //绘制展位
        var cylinderGeometry = new THREE.BoxGeometry(width, height, this.zwg);
        let cubeMaterial1 = new THREE.MeshBasicMaterial({
          color: 0x36855f,
        });
        //纹理贴图
        // var imgs = require('./logo.png')
        // if(a.logo.length>0){
        //   imgs = a.logo[0]
        // }
        // var texture = new THREE.TextureLoader().load(imgs);
        var texture = new THREE.CanvasTexture(
          this.getTextCanvas(a.BoothName, width, height, "#36855f")
        );
        var materials = [
          cubeMaterial1,
          cubeMaterial1,
          cubeMaterial1,
          cubeMaterial1,
          new THREE.MeshBasicMaterial({
            map: texture,
          }), // top
          cubeMaterial1,
        ];
        var cylinder = new THREE.Mesh(cylinderGeometry, materials);
        var testObj = {};

        //绘制边框
        let cubeEdges = new THREE.EdgesGeometry(cylinderGeometry, 1);
        let cubeLine = new THREE.LineSegments(cubeEdges, edgesMtl);

        // //展位位置
        // if (a.hall_rotation_angle == 90) {
        //   testObj.x = a.CoordinateX / this.step - width / 2;
        //   testObj.y = a.CoordinateY / this.step - height / 2;
        // } else if (a.hall_rotation_angle == 270) {
        //   testObj.x = a.CoordinateX / this.step + width / 2;
        //   testObj.y = a.CoordinateY / this.step - height / 2;
        // } else if (a.hall_rotation_angle == 0) {
        //   testObj.x = a.CoordinateX / this.step + width / 2;
        //   testObj.y = a.CoordinateY / this.step - height / 2;
        // } else {
        //   testObj.x = a.CoordinateX / this.step + width / 2;
        //   testObj.y = a.CoordinateY / this.step + height / 2;
        // }

        // //展馆四周展位位置
        // if (a.BoothAngle == 90) {
        //   testObj.x = a.CoordinateX / this.step + width / 2;
        //   testObj.y = a.CoordinateY / this.step - height;
        // } else if (a.BoothAngle == 270) {
        //   testObj.x = a.CoordinateX / this.step - width / 2;
        //   testObj.y = a.CoordinateY / this.step - height;
        // }

        testObj.x = a.CoordinateX;
        testObj.y = a.CoordinateY;

        // testObj.ox = testObj.x;
        // testObj.oy = testObj.y;
        testObj.width = width;
        testObj.height = height;

        //图形，边框位置
        cylinder.position.z = 0;
        cubeLine.position.z = 0;
        console.log(testObj.x);
        cylinder.position.x = testObj.x;
        cylinder.position.y = testObj.y;
        cubeLine.position.x = testObj.x;
        cubeLine.position.y = testObj.y;
        if(this.nowlc == 2){
          cylinder.position.x = testObj.x*1.7;
          cylinder.position.y = testObj.y*0.9;
          cubeLine.position.x = testObj.x*1.7;
          cubeLine.position.y = testObj.y*0.9;
        }
        cylinder.name = a.BoothName;
        cylinder.width = width;
        cylinder.height = height;
        // console.log(a)
        if (a.name_zh && a.name_zh.includes("#")) {
          console.log(a.name_zh);
          a.name_zh = a.name_zh.split("#");
          a.name_en = a.name_en.split("#");
          a.product_en = a.product_en.split("#");
          a.product_zh = a.product_zh.split("#");
        }
        cylinder.xxxx = a;
        // console.log(a);
        this.scene.add(cylinder, cubeLine);
        this.timeRender();
        zaarr.push(testObj);
      }
      //设置墙位置
      zaarr.forEach((a) => {
        //取整
        var startX = Math.floor(a.x - a.width / 2);
        var startY = Math.floor(a.y - a.height / 2);
        for (let i = 0; i <= a.width; i++) {
          for (let j = 0; j <= a.height; j++) {
            var e = [];
            e[0] = startX + i;
            e[1] = startY + j;
            this.ewarr.set(e, "value", 1);
          }
        }
      });

      //跨楼层导航 切换楼层后画出路径
      if (this.klcdh && this.allpoint.length > 0) {
        this.lujing();
      }

      this.fullscreenLoading = false;
    },
    animate() {
      if (this.allpoint.length > 0) {
        this.texture.offset.x -= 0.008;

        if (this.progress > 1.0) {
          this.progress = 0; //停留在管道末端,否则会一直跑到起点 循环再跑
        }
        // this.progress += 0.005;
        var code_value = this.code_value ? this.code_value : 1;
        // console.log(code_value);
        this.progress += (1 / this.curve.points.length) * code_value;
        if (this.curve) {
          let point = this.curve.getPoint(this.progress);
          if (point && point.x) {
            this.circleP.position.set(point.x - 1, point.y, point.z + 2);
          }
        }
      }

      // this.stats.update();

      // 自己调用自己，创建一个循环，不使用setInterval，好处是，离开当前路由，循环将终止，节省资源
      this.myReq = requestAnimationFrame(this.animate);

      //只在需要时渲染
      if (this.renderEnabled) {
        this.renderer.render(this.scene, this.camera);
      }
    },
    lujing() {
      //计算路径
      let that = this;
      //选择展厅的起始位置和终止点
      if (this.topshow) {
        if (this.start.lc == this.end.lc && this.nowlc != this.start.lc) {
          alert("请前往起始位置所在楼层计算导航路径！");
          return;
        }
        this.fullscreenLoading = true;
        if (this.start.lc == this.end.lc) {
          //同层导航
          this.klcdh = false;

          //筛选起止点
          var ggwz = {
            width: 0,
            height: 0,
            position: {
              x: 0,
              y: 0,
            },
          };
          var obj;
          if (!this.start.xxxx.guide_x && !this.end.xxxx.guide_x) {
            obj = this.xzqzd(this.start, this.end);
          } else if (this.start.xxxx.guide_x && this.end.xxxx.guide_x) {
            var ggwz1 = {
              width: 0,
              height: 0,
              position: {
                x: 0,
                y: 0,
              },
            };
            ggwz.position.x = this.start.xxxx.guide_x / this.step;
            ggwz.position.y = this.start.xxxx.guide_y / this.step;
            ggwz1.position.x = this.end.xxxx.guide_x / this.step;
            ggwz1.position.y = this.end.xxxx.guide_y / this.step;
            obj = this.xzqzd(ggwz, ggwz1);
          } else {
            if (this.start.xxxx.guide_x && this.start.xxxx.guide_y) {
              ggwz.position.x = this.start.xxxx.guide_x / this.step;
              ggwz.position.y = this.start.xxxx.guide_y / this.step;
              obj = this.xzqzd(ggwz, this.end);
            }
            if (this.end.xxxx.guide_x && this.end.xxxx.guide_y) {
              ggwz.position.x = this.end.xxxx.guide_x / this.step;
              ggwz.position.y = this.end.xxxx.guide_y / this.step;
              obj = this.xzqzd(this.start, ggwz);
            }
          }
          // let obj = this.xzqzd(this.start, this.end);

          //A*计算路径
          let astar = new Astar(that.ewarr);
          let path = astar.search(
            obj.s, // start
            obj.e, // end
            {
              // option
              rightAngle: true, // default:false,Allow diagonal  是否走直角路线
              optimalResult: true, // default:true,In a few cases, the speed is slightly slower
            }
          );

          if (!path || path.length < 1) {
            alert("暂无路径");
            return;
          }
          let allpoint = [];
          for (let i = 0; i < path.length; i++) {
            var e = path[i];
            allpoint.push({
              x: e[0],
              y: e[1],
            });
          }
          this.allpoint = allpoint;
          this.tongdao(allpoint);
        } else {
          //跨层导航
          this.klcdh = true;
          var lcwz = {
            width: 0,
            height: 0,
            position: {
              x: 0,
              y: 0,
            },
          };
          console.log(this.start, this.end, this.ltarr);

          // let namestr = this.start.name.split("-")[0];
          let b = this.ltarr[0].cfp_x - this.start.xxxx.CoordinateX * this.step;
          let c = this.ltarr[0].cfp_y - this.start.xxxx.CoordinateY * this.step;
          var mainlin = {
            jl: Math.sqrt(b * b + c * c),
            index: 0,
          };
          this.ltarr.forEach((e, i) => {
            // if (
            //   e.cross_floor_point_name.includes(namestr) &&
            //   e.hall_floor == this.start.lc
            // ) {
            //   console.log(namestr, e);
            //   lcwz.position.x = e.cfp_x / this.step;
            //   lcwz.position.y = e.cfp_y / this.step;
            // }
            if (e.hall_floor == this.start.lc) {
              let x = e.cfp_x - this.start.xxxx.CoordinateX * this.step;
              let y = e.cfp_y - this.start.xxxx.CoordinateY * this.step;
              let a = Math.sqrt(x * x + y * y);
              console.log(a, e);
              if (mainlin.jl > a) {
                mainlin.jl = a;
                mainlin.index = i;
              }
            }
          });
          lcwz.position.x = this.ltarr[mainlin.index].cfp_x / this.step;
          lcwz.position.y = this.ltarr[mainlin.index].cfp_y / this.step;
          var path;
          var ggwz = {
            width: 0,
            height: 0,
            position: {
              x: 0,
              y: 0,
            },
          };
          if (this.start.lc == this.nowlc) {
            if (this.start.xxxx.guide_x) {
              ggwz.position.x = this.start.xxxx.guide_x / this.step;
              ggwz.position.y = this.start.xxxx.guide_y / this.step;
              let obj1 = this.xzqzd(ggwz, lcwz);
              //A*计算路径
              let astar = new Astar(this.ewarr);
              path = astar.search(
                obj1.s, // start
                obj1.e, // end
                {
                  rightAngle: true, // default:false,Allow diagonal  是否走直角路线
                  optimalResult: true, // default:true,In a few cases, the speed is slightly slower
                }
              );
            } else {
              //筛选起止点
              let obj1 = this.xzqzd(this.start, lcwz);
              //A*计算路径
              let astar = new Astar(this.ewarr);
              path = astar.search(
                obj1.s, // start
                obj1.e, // end
                {
                  rightAngle: true, // default:false,Allow diagonal  是否走直角路线
                  optimalResult: true, // default:true,In a few cases, the speed is slightly slower
                }
              );
            }
          } else if (this.end.lc == this.nowlc) {
            if (this.end.xxxx.guide_x) {
              ggwz.position.x = this.end.xxxx.guide_x / this.step;
              ggwz.position.y = this.end.xxxx.guide_y / this.step;
              let obj1 = this.xzqzd(lcwz, ggwz);
              //A*计算路径
              let astar = new Astar(this.ewarr);
              path = astar.search(
                obj1.s, // start
                obj1.e, // end
                {
                  rightAngle: true, // default:false,Allow diagonal  是否走直角路线
                  optimalResult: true, // default:true,In a few cases, the speed is slightly slower
                }
              );
            } else {
              let obj1 = this.xzqzd(lcwz, this.end);
              //A*计算路径
              let astar = new Astar(this.ewarr);

              path = astar.search(
                obj1.s, // start
                obj1.e, // end
                {
                  rightAngle: true, // default:false,Allow diagonal  是否走直角路线
                  optimalResult: true, // default:true,In a few cases, the speed is slightly slower
                }
              );
            }
          }
          if (!path || path.length < 1) {
            alert("暂无路径");
            return;
          }
          let allpoint = [];
          for (let i = 0; i < path.length; i++) {
            var e = path[i];
            allpoint.push({
              x: e[0],
              y: e[1],
            });
          }
          this.allpoint = allpoint;
          this.tongdao(allpoint);

          if (this.nowlc == this.start.lc) {
            // let canvas = document.createElement("canvas");
            // let ctx = canvas.getContext("2d");

            // var img = new Image();
            // img.onload = () => {
            //   // 画图(这里画布与图片等宽高)
            //   ctx.drawImage(img, 0, 0);
            //   // 设置字体大小
            //   ctx.font = "18px Microsoft YaHei";
            //   // 更改字号后，必须重置对齐方式，否则居中麻烦。设置文本的垂直对齐方式
            //   // ctx.textBaseline = "middle";
            //   // ctx.textAlign = "center";
            //   // 距离左边的位置
            //   var left = canvas.width * 0.05;
            //   // // 距离上边的位置 (图片高-文字距离图片底部的距离)
            //   // var top = canvas.height * 0.14;
            //   // 文字颜色
            //   ctx.fillStyle = "#ffffff";
            //   // 文字在画布的位置
            //   ctx.fillText(
            //     "乘梯至F" + this.end.lc + "层，点击",
            //     left,
            //     canvas.height * 0.15
            //   );
            //   ctx.fillText(
            //     "查看F" + this.end.lc + "层路线图",
            //     left,
            //     canvas.height * 0.34
            //   );
            // };
            // img.src = require("./tkbackground.png");

            // let texture = new THREE.Texture(canvas);
            // texture.needsUpdate = true;
            var url;
            if (this.end.lc == 1) {
              url = require("./lcbag.png");
            } else {
              url = require("./lcbag2.png");
            }
            var texture = new THREE.TextureLoader().load(url);
            //使用Sprite显示文字
            let material = new THREE.SpriteMaterial({ map: texture });
            let textObj = new THREE.Sprite(material);
            textObj.scale.set(23, 15, 0);
            textObj.center = new THREE.Vector2(0, 0);
            textObj.position.set(lcwz.position.x - 11.5, lcwz.position.y, 5);
            this.textObj = textObj;

            this.scene.add(textObj);

            // var texture = new THREE.CanvasTexture(
            //   this.getTextCanvas(
            //     "乘梯至F" + this.end.lc + "层，点击查看F" + this.end.lc + "层路线图",
            //     Math.floor(7),
            //     Math.floor(3),
            //     "#36855f"
            //   )
            // );

            // //绘制展位背景
            // var cylinderGeometry = new THREE.PlaneGeometry(
            //   7,
            //   3
            // );
            // //位置
            // var textObj = new THREE.Mesh(
            //   cylinderGeometry,
            //   new THREE.MeshBasicMaterial({
            //     map: texture,
            //   })
            // );
            // textObj.position.set(lcwz.position.x, lcwz.position.y+3, 5);
            // textObj.kcdh = true
            // this.scene.add(textObj);
            // this.textObj = textObj;
          }
        }

        return;
      }

      this.topshow = true;
      this.buttntxt = "规划路线（Plan Route）";
    },
    xzqzd(nowstar, nowend) {
      //起点已选择
      var start, end;
      // let nowstar = this.start;
      // let nowend = this.end;

      let startx = Math.floor(nowstar.position.x); //中心
      let startx1 = Math.floor(nowstar.position.x - nowstar.width / 2); //左
      let startx2 = Math.floor(nowstar.position.x + nowstar.width / 2); //右
      let starty = Math.floor(nowstar.position.y); //中心
      let starty1 = Math.floor(nowstar.position.y - nowstar.height / 2); //下
      let starty2 = Math.floor(nowstar.position.y + nowstar.height / 2); //上

      let endx = Math.floor(nowend.position.x);
      let endx1 = Math.floor(nowend.position.x - nowend.width / 2);
      let endx2 = Math.floor(nowend.position.x + nowend.width / 2);
      let endy = Math.floor(nowend.position.y);
      let endy1 = Math.floor(nowend.position.y - nowend.height / 2);
      let endy2 = Math.floor(nowend.position.y + nowend.height / 2);

      //根据相对位置选择起始点终止点
      if (nowstar.position.x == nowend.position.x) {
        //上下
        if (nowstar.position.y > nowend.position.y) {
          //终点在下
          //起始点
          //起点位于展位底部
          start = [startx, starty1];
          if (
            this.ewarr.get(start).value == 1 &&
            this.ewarr.get([startx, starty1 - 1]).value == 1
          ) {
            //起点为墙
            //起点位于展位左侧
            start = [startx1, starty];
            if (
              this.ewarr.get(start).value == 1 &&
              this.ewarr.get([startx1 - 1, starty]).value == 1
            ) {
              //起点位于展位右侧
              start = [startx2, starty];
              if (
                this.ewarr.get(start).value == 1 &&
                this.ewarr.get([startx2 + 1, starty]).value == 1
              ) {
                //起点位于展位顶部
                start = [startx, starty2];
              }
            }
          }

          //终点
          //终点位于展位顶部
          end = [endx, endy2];
          if (
            this.ewarr.get(end).value == 1 &&
            this.ewarr.get([endx, endy2 + 1]).value == 1
          ) {
            //终点为墙
            //终点位于展位左侧
            end = [endx1, endy];
            if (
              this.ewarr.get(end).value == 1 &&
              this.ewarr.get([endx1 - 1, endy]).value == 1
            ) {
              //终点位于展位右侧
              end = [endx2, endy];
              if (
                this.ewarr.get(end).value == 1 &&
                this.ewarr.get([endx2 + 1, endy]).value == 1
              ) {
                //终点位于展位底部
                end = [endx, endy1];
              }
            }
          }
        } else {
          //终点位于起点上方
          //起始点
          //起点位于展位顶部
          start = [startx, starty2];
          if (
            this.ewarr.get(start).value == 1 &&
            this.ewarr.get([startx, starty2 + 1]).value == 1
          ) {
            //起点为墙
            //起点位于展位左侧
            start = [startx1, starty];
            if (
              this.ewarr.get(start).value == 1 &&
              this.ewarr.get([startx1 - 1, starty]).value == 1
            ) {
              //起点位于展位右侧
              start = [startx2, starty];
              if (
                this.ewarr.get(start).value == 1 &&
                this.ewarr.get([startx2 + 1, starty]).value == 1
              ) {
                //起点位于展位底部
                start = [startx, starty1];
              }
            }
          }

          //终点
          //终点位于展位底部
          end = [endx, endy1];
          if (
            this.ewarr.get(end).value == 1 &&
            this.ewarr.get([endx, endy1 - 1]).value == 1
          ) {
            //终点为墙
            //终点位于展位左侧
            end = [endx1, endy];
            if (
              this.ewarr.get(end).value == 1 &&
              this.ewarr.get([endx1 - 1, endy]).value == 1
            ) {
              //终点位于展位右侧
              end = [endx2, endy];
              if (
                this.ewarr.get(end).value == 1 &&
                this.ewarr.get([endx2 + 1, endy]).value == 1
              ) {
                //终点位于展位顶部
                end = [endx, endy2];
              }
            }
          }
        }
      } else if (nowstar.position.x > nowend.position.x) {
        //终点位于左侧
        if (nowstar.position.y > nowend.position.y) {
          //左下
          //起始点
          //起点位于展位底部
          start = [startx, starty1];
          if (
            this.ewarr.get(start).value == 1 &&
            this.ewarr.get([startx, starty1 - 1]).value == 1
          ) {
            //起点为墙
            //起点位于展位左侧
            start = [startx1, starty];
            if (
              this.ewarr.get(start).value == 1 &&
              this.ewarr.get([startx1 - 1, starty]).value == 1
            ) {
              //起点位于展位顶部
              start = [startx, starty2];
              if (
                this.ewarr.get(start).value == 1 &&
                this.ewarr.get([startx, starty2 + 1]).value == 1
              ) {
                //起点位于展位右侧
                start = [startx2, starty];
              }
            }
          }

          //终点
          //终点位于展位右侧
          end = [endx2, endy];
          if (
            this.ewarr.get(end).value == 1 &&
            this.ewarr.get([endx2 + 1, endy]).value == 1
          ) {
            //终点为墙
            //终点位于展位顶部
            end = [endx, endy2];
            if (
              this.ewarr.get(end).value == 1 &&
              this.ewarr.get([endx, endy2 + 1]).value == 1
            ) {
              //终点位于展位左侧
              end = [endx1, endy];
              if (
                this.ewarr.get(end).value == 1 &&
                this.ewarr.get([endx1 - 1, endy]).value == 1
              ) {
                //终点位于展位底部
                end = [endx, endy1];
              }
            }
          }
        } else if (nowstar.position.y < nowend.position.y) {
          //左上
          //起始点
          //起点位于展位左侧
          start = [startx1, starty];
          if (
            this.ewarr.get(start).value == 1 &&
            this.ewarr.get([startx1 - 1, starty]).value == 1
          ) {
            //起点为墙
            //起点位于展位顶部
            start = [startx, starty2];
            if (
              this.ewarr.get(start).value == 1 &&
              this.ewarr.get([startx, starty2 + 1]).value == 1
            ) {
              //起点位于展位右侧
              start = [startx2, starty];
              if (
                this.ewarr.get(start).value == 1 &&
                this.ewarr.get([startx2 + 1, starty]).value == 1
              ) {
                //起点位于展位底部
                start = [startx, starty1];
              }
            }
          }

          //终点
          //终点位于展位底部
          end = [endx, endy1];
          if (
            this.ewarr.get(end).value == 1 &&
            this.ewarr.get([endx, endy1 - 1]).value == 1
          ) {
            //终点为墙
            //终点位于展位右侧
            end = [endx2, endy];
            if (
              this.ewarr.get(end).value == 1 &&
              this.ewarr.get([endx2 + 1, endy]).value == 1
            ) {
              //终点位于展位顶部
              end = [endx, endy2];
              if (
                this.ewarr.get(end).value == 1 &&
                this.ewarr.get([endx, endy2 + 1]).value == 1
              ) {
                //终点位于展位左侧
                end = [endx1, endy];
              }
            }
          }
        } else {
          //正左侧
          //起始点
          //起点位于展位左侧
          start = [startx1, starty];
          if (
            this.ewarr.get(start).value == 1 &&
            this.ewarr.get([startx1 - 1, starty]).value == 1
          ) {
            //起点为墙
            //起点位于展位顶部
            start = [startx, starty2];
            if (
              this.ewarr.get(start).value == 1 &&
              this.ewarr.get([startx, starty2 + 1]).value == 1
            ) {
              //起点位于展位底部
              start = [startx, starty1];
              if (
                this.ewarr.get(start).value == 1 &&
                this.ewarr.get([startx, starty1 - 1]).value == 1
              ) {
                //起点位于展位右侧
                start = [startx2, starty];
              }
            }
          }

          //终点
          //终点位于展位右侧
          end = [endx2, endy];
          if (
            this.ewarr.get(end).value == 1 &&
            this.ewarr.get([endx2 + 1, endy]).value == 1
          ) {
            //终点为墙
            //终点位于展位顶部
            end = [endx, endy2];
            if (
              this.ewarr.get(end).value == 1 &&
              this.ewarr.get([endx, endy2 + 1]).value == 1
            ) {
              //终点位于展位底部
              end = [endx, endy1];
              if (
                this.ewarr.get(end).value == 1 &&
                this.ewarr.get([endx, endy1 - 1]).value == 1
              ) {
                //终点位于展位左侧
                end = [endx1, endy];
              }
            }
          }
        }
      } else if (nowstar.position.x < nowend.position.x) {
        //终点位于右侧
        if (nowstar.position.y > nowend.position.y) {
          //右下
          //起始点
          //起点位于展位底部
          start = [startx, starty1];
          if (
            this.ewarr.get(start).value == 1 &&
            this.ewarr.get([startx, starty1 - 1]).value == 1
          ) {
            //起点为墙
            //起点位于展位右侧
            start = [startx2, starty];
            if (
              this.ewarr.get(start).value == 1 &&
              this.ewarr.get([startx2 + 1, starty]).value == 1
            ) {
              //起点位于展位顶部
              start = [startx, starty2];
              if (
                this.ewarr.get(start).value == 1 &&
                this.ewarr.get([startx, starty2 + 1]).value == 1
              ) {
                //起点位于展位左侧
                start = [startx1, starty];
              }
            }
          }

          //终点
          //终点位于展位左侧
          end = [endx1, endy];
          if (
            this.ewarr.get(end).value == 1 &&
            this.ewarr.get([endx1 - 1, endy]).value == 1
          ) {
            //终点为墙
            //终点位于展位顶部
            end = [endx, endy2];
            if (
              this.ewarr.get(end).value == 1 &&
              this.ewarr.get([endx, endy2 + 1]).value == 1
            ) {
              //终点位于展位右侧
              end = [endx2, endy];
              if (
                this.ewarr.get(end).value == 1 &&
                this.ewarr.get([endx2 + 1, endy]).value == 1
              ) {
                //终点位于展位底部
                end = [endx, endy1];
              }
            }
          }
        } else if (nowstar.position.y < nowend.position.y) {
          //右上
          //起始点
          //起点位于展位右侧
          start = [startx2, starty];
          if (
            this.ewarr.get(start).value == 1 &&
            this.ewarr.get([startx2 + 1, starty]).value == 1
          ) {
            //起点为墙
            //起点位于展位顶部
            start = [startx, starty2];
            if (
              this.ewarr.get(start).value == 1 &&
              this.ewarr.get([startx, starty2 + 1]).value == 1
            ) {
              //起点位于展位左侧
              start = [startx1, starty];
              if (
                this.ewarr.get(start).value == 1 &&
                this.ewarr.get([startx1 - 1, starty]).value == 1
              ) {
                //起点位于展位底部
                start = [startx, starty1];
              }
            }
          }

          //终点
          //终点位于展位底部
          end = [endx, endy1];
          if (
            this.ewarr.get(end).value == 1 &&
            this.ewarr.get([endx, endy1 - 1]).value == 1
          ) {
            //终点为墙
            //终点位于展位左侧
            end = [endx1, endy];
            if (
              this.ewarr.get(end).value == 1 &&
              this.ewarr.get([endx1 - 1, endy]).value == 1
            ) {
              //终点位于展位顶部
              end = [endx, endy2];
              if (
                this.ewarr.get(end).value == 1 &&
                this.ewarr.get([endx, endy2 + 1]).value == 1
              ) {
                //终点位于展位右侧
                end = [endx2, endy];
              }
            }
          }
        } else {
          //正右侧
          //起始点
          //起点位于展位右侧
          start = [startx2, starty];
          if (
            this.ewarr.get(start).value == 1 &&
            this.ewarr.get([startx2 + 1, starty]).value == 1
          ) {
            //起点为墙
            //起点位于展位顶部
            start = [startx, starty2];
            if (
              this.ewarr.get(start).value == 1 &&
              this.ewarr.get([startx, starty2 + 1]).value == 1
            ) {
              //起点位于展位底部
              start = [startx, starty1];
              if (
                this.ewarr.get(start).value == 1 &&
                this.ewarr.get([startx, starty1 - 1]).value == 1
              ) {
                //起点位于展位左侧
                start = [startx1, starty];
              }
            }
          }

          //终点
          //终点位于展位左侧
          end = [endx1, endy];
          if (
            this.ewarr.get(end).value == 1 &&
            this.ewarr.get([endx1 - 1, endy]).value == 1
          ) {
            //终点为墙
            //终点位于展位顶部
            end = [endx, endy2];
            if (
              this.ewarr.get(end).value == 1 &&
              this.ewarr.get([endx, endy2 + 1]).value == 1
            ) {
              //终点位于展位底部
              end = [endx, endy1];
              if (
                this.ewarr.get(end).value == 1 &&
                this.ewarr.get([endx, endy1 - 1]).value == 1
              ) {
                //终点位于展位右侧
                end = [endx2, endy];
              }
            }
          }
        }
      }
      return { s: start, e: end };
    },
    tongdao(e) {
      /**
       * 创建一个设置重复纹理的管道
       */
      let path = [];
      for (const i in e) {
        const element = e[i];
        path.push(new THREE.Vector3(element.x, element.y, 0));
      }
      // console.log(e,path);
      var curve = new THREE.CatmullRomCurve3(path, false /*是否闭合*/);
      this.curve = curve;
      var tubeGeometry = new THREE.TubeGeometry(
        curve,
        e.length - 1,
        0.3,
        4,
        false
      );
      var texture = new THREE.TextureLoader().load(require("./run2.png"));
      // 设置阵列模式为 RepeatWrapping
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      // // 设置x方向的偏移(沿着管道路径方向)，y方向默认1
      // texture.repeat.x = 20;
      // 设置管道纹理偏移数,便于对中
      texture.offset.y = 0.5;
      this.texture = texture;
      var tubeMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
      });
      var tube = new THREE.Mesh(tubeGeometry, tubeMaterial);
      this.tubearr.push(tube);
      this.scene.add(tube);
      this.timeRender();

      /**
       * 创建一个半透明管道
       */
      var tubeGeometry2 = new THREE.TubeGeometry(
        curve,
        e.length - 1,
        0.32,
        4,
        false
      );
      var tubeMaterial2 = new THREE.MeshPhongMaterial({
        color: 0x007d65,
        transparent: true,
        opacity: 0.3,
      });
      var tube2 = new THREE.Mesh(tubeGeometry2, tubeMaterial2);
      this.scene.add(tube2);
      this.tubearr.push(tube2);

      // let geometryP = new THREE.BoxGeometry(2, 2, 2);
      var texture1 = new THREE.TextureLoader().load(require("./people.png"));
      var materialP = new THREE.SpriteMaterial({ map: texture1 });
      this.circleP = new THREE.Sprite(materialP);
      this.circleP.scale.set(5, 5, 0);
      this.circleP.center = new THREE.Vector2(0, 0);
      this.scene.add(this.circleP);
      // this.circleP.rotateY(Math.PI / 2);

      this.fullscreenLoading = false;
    },
    close() {
      //点击关闭 清除画出的路线
      this.daohang = false;
      this.topshow = false;
      this.xinxi = false;
      this.klcdh = false;
      this.zzjtgs = false;
      this.allpoint = [];
      this.progress = 0;
      this.buttntxt = "到这去（Go Here）";
      //清除画出的路线
      for (let i = 0; i < this.tubearr.length; i++) {
        let e = this.tubearr[i];
        this.scene.remove(e);
      }
      this.scene.remove(this.circleP);
      this.scene.remove(this.textObj);
      this.tubearr = [];
    },
    background() {
      this.axios
        .get(
          `/emanual/interface_new.php?key_string_base64=${
            this.key_string_base64
          }&interface_index=map_interface${this.nowlc == 1 ? "" : "02"}`
        )
        .then((res) => {
          let obj = res.data[0];
          var url;
          for (let k in obj) {
            if (Object.hasOwnProperty.call(obj, k)) {
              url = obj[k];
            }
          }
          // console.log(url);

          
          if(this.nowlc == 2){
            //绘制展位背景
            var cylinderGeometry = new THREE.PlaneGeometry(
              this.Hwidth/1.6,
              this.Hheight/1.5
            );
          }else{
            //绘制展位背景
            var cylinderGeometry = new THREE.PlaneGeometry(
              this.Hwidth,
              this.Hheight
            );
          }
          //纹理贴图
          var texture = new THREE.TextureLoader().load(url);
          //位置
          var cylinder = new THREE.Mesh(
            cylinderGeometry,
            new THREE.MeshBasicMaterial({
              map: texture,
            })
          );
          cylinder.position.x = this.Hwidth / 1.95;
          cylinder.position.y = this.Hheight / 2.02;
          this.scene.add(cylinder);
        });

      //画出网格坐标
      // var material = new THREE.LineBasicMaterial({
      //         color: 0xffffff,
      //       });
      //       for(let i = 0;i<350;i++){
      //         var points = [];
      //         points.push(new THREE.Vector3(i, 0, 0));
      //         points.push(new THREE.Vector3(i, 350, 0));
      //         var geometry = new THREE.BufferGeometry().setFromPoints(points);
      //         var line = new THREE.Line(geometry, material);
      //         this.scene.add(line);

      //         var points1 = [];
      //         points1.push(new THREE.Vector3(0, i, 0));
      //         points1.push(new THREE.Vector3(350, i, 0));
      //         var geometry1 = new THREE.BufferGeometry().setFromPoints(points1);
      //         var line1 = new THREE.Line(geometry1, material);
      //         this.scene.add(line1);
      //       }
    },
    clickevent() {
      var that = this;
      this.raycaster = new THREE.Raycaster();
      this.mouse = new THREE.Vector2();
      var SELECTED;
      //判断是否为移动端
      let isMobel = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      this.isMobel = isMobel;
      var events;
      if (isMobel) {
        //移动段监听触摸事件
        events = "touchstart";
      } else {
        events = "click";
      }
      // console.log(event);
      //监听全局点击/触摸事件,通过ray检测选中哪一个object
      document.addEventListener(
        events,
        (event) => {
          // console.log(event,document.getElementById("container1").clientWidth,document.getElementById("container1").clientHeight);

          if (event.path) {
            // console.log(event)
            let event_name = event.path[0].className;
            // console.log(event_name);
            if (event_name) {
              // if(this.ssjg){
              //   this.ssjg = false
              // }else{
              //   return
              // }
              return;
            }
            if (
              event_name != "el-input__inner" &&
              event_name != "ssjg_div" &&
              event_name != "cxlist" &&
              event_name != "llspan" &&
              this.ssjg &&
              event_name != "el-button el-button--default" &&
              event_name != "el-icon-search"
            ) {
              this.ssjg = false;
              // if(!event_name){
              //   return
              // }
              // return
            }
            if (
              event_name == "button_div" ||
              event_name == "ckxx" ||
              event_name == "name_div_li" ||
              event_name == "cxlist" ||
              event_name == "gb_img"
            ) {
              return;
            }
          }
          event.preventDefault();
          //threejs坐标点的标准化
          // console.log(event);
          if (event.touches) {
            //触摸事件
            this.mouse.x =
              (event.touches[0].pageX / this.renderer.domElement.clientWidth) *
                2 -
              1;
            this.mouse.y =
              -(
                event.touches[0].pageY / this.renderer.domElement.clientHeight
              ) *
                2 +
              1;
          } else {
            //点击事件
            this.mouse.x =
              (event.clientX / this.renderer.domElement.clientWidth) * 2 - 1;
            this.mouse.y =
              -(event.clientY / this.renderer.domElement.clientHeight) * 2 + 1;
          }
          console.log(this.mouse);
          this.raycaster.setFromCamera(this.mouse, this.camera);
          this.scene.children.forEach((child) => {
            if (child instanceof THREE.Mesh) {
              //根据需求判断哪些加入objects,也可以在生成object的时候push进objects
              this.objects.push(child);
            }
            if (child instanceof THREE.Sprite) {
              //根据需求判断哪些加入objects,也可以在生成object的时候push进objects
              // console.log(child)
              this.objects2.push(child);
            }
          });
          var intersects = this.raycaster.intersectObjects(this.objects);

          var intersects2 = this.raycaster.intersectObjects(this.objects2);

          if (intersects2.length > 0) {
            this.xzlc(this.end.lc);
          }

          if (intersects.length > 0) {
            if (SELECTED != intersects[0].object) {
              // console.log(SELECTED);
              if (SELECTED && SELECTED.xxxx) {
                //选择其他展位时还原之前展位颜色
                let map = new THREE.CanvasTexture(
                  that.getTextCanvas(
                    SELECTED.name,
                    SELECTED.width,
                    SELECTED.height,
                    "#36855f"
                  )
                );
                SELECTED.material.forEach((e) => {
                  if (e.map) {
                    e.map = map;
                  } else {
                    e.color.set(0x36855f);
                  }
                });
              }

              SELECTED = intersects[0].object;
              // console.log(SELECTED);
              //点击背景等时不弹出导航框
              if (!SELECTED.xxxx) {
                return;
              }
              //判断是否点击为展位
              if (SELECTED.xxxx) {
                //改变颜色
                let map = new THREE.CanvasTexture(
                  that.getTextCanvas(
                    SELECTED.name,
                    SELECTED.width,
                    SELECTED.height,
                    "#fd8a45"
                  )
                );
                SELECTED.material.forEach((e) => {
                  if (e.map) {
                    e.map = map;
                  } else {
                    e.color.set(0xfd8a45);
                  }
                });
              }
            }
            // console.log(SELECTED);
            //点击背景等时不弹出导航框
            if (!SELECTED.xxxx) {
              return;
            }
            if (this.topshow) {
              //起点
              this.fromname = SELECTED.name;
              this.start = SELECTED;
              this.start.lc = this.nowlc;
            } else {
              //终点
              // //点击背景等时不弹出导航框
              // if (!SELECTED.xxxx) {
              //   return;
              // }
              let xxxx = SELECTED.xxxx;
              var name_zh = [],
                name_en = [],
                product_zh = [],
                product_en = [];
              this.orderlist.forEach((e) => {
                if (e.BoothName == xxxx.BoothName) {
                  console.log(e.BoothName, xxxx.BoothName);
                  name_zh.push(e.CompanyZHName);
                  name_en.push(e.CompanyENName);
                  product_zh.push(e.product_zh);
                  product_en.push(e.product_en);
                  if (name_zh.length < 2) {
                    xxxx.name_en = name_en[0];
                    xxxx.name_zh = name_zh[0];
                    xxxx.product_zh = product_zh[0];
                    xxxx.product_en = product_en[0];
                  } else {
                    xxxx.name_en = name_en;
                    xxxx.name_zh = name_zh;
                    xxxx.product_zh = product_zh;
                    xxxx.product_en = product_en;
                  }
                }
              });
              if (xxxx.name_zh == null) {
                xxxx.name_zh = "";
              }
              console.log(xxxx);
              // return
              this.daohang = true;
              this.toname = SELECTED.name;
              this.end = SELECTED;
              this.zwxq = xxxx;
              this.end.lc = this.nowlc;
            }
            // console.log(this.start, this.end);
          }
        },
        false
      );
    },
    timeRender() {
      //调用一次可以渲染三秒
      //设置为可渲染状态
      this.renderEnabled = true;
      //清除上次的延迟器
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }

      this.timeOut = setTimeout(function () {
        this.renderEnabled = false;
      }, 3000);
    },
  },
  mounted() {
    (this.camera = null),
      (this.scene = null),
      (this.renderer = null),
      (this.labelRenderer = null),
      (this.raycaster = null),
      (this.mouse = null),
      (this.start = null),
      (this.end = null),
      (this.ewarr = null),
      (this.astar = null),
      (this.texture = null),
      (this.fullscreenLoading = true),
      (this.timeOut = null),
      (this.stats = null),
      (this.objects = []),
      (this.objects2 = []),
      (this.objects3 = []),
      (this.tubearr = []),
      (this.curve = null),
      (this.circleP = null),
      (this.textObj = null),
      (this.SELECTED1 = null),
      (this.myReq = null);

    //vuex取值
    this.key_string_base64 = this.$store.state.key_string_base64;

    // //性能监测
    // this.stats = new Stats();
    // document.body.appendChild(this.stats.dom);
    console.log(this.$store.state);
    //每次材质和纹理更新，触发重新渲染
    THREE.DefaultLoadingManager.onLoad = () => {
      this.timeRender();
    };

    //初始化地图
    this.init();
    //背景
    this.background();

    //全局监听点击/触摸事件
    this.clickevent();

    this.animate();
  },
};
</script>
<style scoped>
* {
  touch-action: pan-y;
  box-sizing: border-box;
}
.zwxxpspan {
  cursor: pointer;
}
.llspan {
  display: flex;
  align-items: center;
}
.cxlist {
  height: 35px;
  line-height: 35px;
  padding: 0 0 0 8px;
  overflow: hidden;
}
.cxlist:not(:last-child) {
  border-bottom: 1px solid rgb(224, 224, 224);
}
.ssjg_div {
  height: 430px;
  background: white;
  overflow-y: auto;
}
.ss_div {
  position: absolute;
  /* width: 380px; */
  top: 25px;
  left: 20px;
  z-index: 9;
}
.divdemo {
  background: #2abfff;
  width: 300px;
  height: 300px;
}
.checked {
  background-color: cornflowerblue !important;
}

.cs_div {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.lc_div {
  position: absolute;
  bottom: 300px;
  left: 20px;
  box-shadow: 0px -6px 6px 0px rgb(0 0 0 / 10%);
}

.cs_div:not(:last-child) {
  border-bottom: 1px black solid;
}

.ckxx {
  float: right;
  color: #2abfff;
  margin: 15px 10px 0 0;
  cursor: pointer;
}

.zhanwei_div {
  height: 140px;
  overflow: auto;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.lsbj {
  background-color: #35855e;
  border-radius: 100px;
  width: 120px;
  display: inline-block;
  color: #ffffff;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
  margin-top: 15px;
}

.zwxxli:not(:last-child) {
  padding-bottom: 5px;
  border-bottom: 1px solid black;
}

.tk_div {
  position: absolute;
  top: 100px;
  background-color: #ffffff;
  /* height: 600px; */
  width: 700px;
  left: calc(50vw - 350px);
  padding: 0 15px 10px 15px;
  z-index: 10;
}

.container {
  height: 100%;
  width: 100%;
}

.right_div {
  position: absolute;
  bottom: 30px;
  left: 30px;
  background-color: #ffffff;
  /* height: 120px; */
  /* width: 500px; */
  max-width: 500px;
  z-index: 10;
}

.button_div {
  cursor: pointer;
  text-align: center;
  /* position: absolute; */
  /* bottom: 10px; */
  /* left: 20px; */
  margin-left: 20px;
  margin-bottom: 10px;
  color: #ffffff;
  line-height: 40px;
  /* width: 460px; */
  height: 40px;
  margin-right: 10px;
  background-color: #2abfff;
  box-shadow: 0px -6px 6px 0px rgb(0 0 0 / 10%);
}

.wz_img {
  width: 42px;
  height: 45px;
}

.name_div {
  /* height: 130px; */
  margin-left: 10px;
  margin-top: 15px;
}

.gb_img {
  cursor: pointer;
  height: 21px;
  width: 21px;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 990;
}

.top_lidiv > span {
  color: #2abfff;
}

.top_lidiv {
  border-bottom: solid #e0e0e0 1px;
}
</style>
